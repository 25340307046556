.container {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 4px;
}

.swiperSlide {
  background: white;
  color: black;
  border: 1px solid var(--gray-300);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 8px;
  height: 100px !important;
  width: 84px !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
}

.selectedSlide {
  background: var(--green-800);
  color: white;
  border: none;
}

.pastDate {
  background: rgb(242, 242, 250);
}

.navArrowLeft,
.navArrowRight {
  cursor: pointer;
}
