.consultationForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 0;
}

.consultationForm input,
.consultationForm select,
.consultationForm textarea {
  height: 48px;
  border-radius: 12px !important;
  color: var(--gray-500, #717186);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
}

.saveButton:hover {
  text-decoration: underline;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  position: relative;
}

.buttonRow button {
  flex-grow: 1;
}

.buttonRow .submitButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.buttonRow .cancelButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  width: 237px;
  height: 48px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 44px;
}

.formLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.formTooltip {
  position: relative;
  margin-top: -4px;
}

.checkboxLabel {
  color: var(--gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
  display: flex;
  gap: 8px;
}

.sectionLegend {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 16px;
}

.bottomSectionInputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.servicesGroup {
  padding: 24px 0;
}

.consultationForm input {
  height: 48px;
}

.datePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
}

.datePickerWrapper fieldset {
  border: none;
}

.openModalButton {
  background-color: white;
  color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}

.formLabel {
  color: var(--Gray-950);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.comboboxContainer {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.comboboxInput {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.comboboxInput:focus {
  border-color: #328170;
  box-shadow: 0 0 0 2px rgba(50, 129, 112, 0.1);
}

.helperText {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  padding-left: 4px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdownItem {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

/* Style for non-clickable dropdown items (messages) */
.dropdownItem:only-child {
  cursor: default;
  text-align: center;
}

.dropdownItem:only-child:hover {
  background-color: transparent;
}

.noResults {
  color: #666;
  font-weight: 500;
  text-align: center;
  padding: 8px 0;
}

.noResultsHelper {
  font-size: 12px;
  color: #666;
  text-align: center;
  padding-bottom: 8px;
}

.drugName {
  font-weight: 600;
  color: var(--Gray-950);
}

.idcCode {
  min-width: 100px;
}

.drugDetails {
  font-size: 14px;
  color: var(--Gray-700);
  margin-top: 4px;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.container {
  width: 100%;
}

.manualToggle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 15px;
  cursor: pointer;
}

.manualInputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
}

.inputGroupGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.selectedDrug {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.selectedDrugInfo {
  flex: 1;
}

.drugName {
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.drugDetails {
  font-size: 12px;
  color: #666;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  border-radius: 50%;
  color: #666;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s;
}

.removeButton:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #dc3545;
}

.datePickerWrapper > div > div {
  height: 48px;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
  font-family: "nunito";
  font-size: 14px;
  background-color: var(--white);
}

.datePickerWrapper fieldset {
  border: none;
}
