.verticalDivider {
  background: var(--gray-200);
  height: 100%;
  width: 1px;
}

.horizontalDivider {
  background: var(--gray-200);
  width: 100%;
  height: 1px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}

.header {
  display: grid;
  align-items: center;
  grid-template-columns: 110px 1fr;
  gap: 12px;
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  gap: 12px;
}

.outlinedButton {
  background: white;
  border: 1px solid var(--green-800);
  color: var(--green-800);
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}

.profileImg {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green-800);
  color: white;
  font-weight: 600;
  font-size: 32px;
}

.patientName {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 !important;
}

.patientDOB {
  font-size: 16px;
  color: var(--gray-950);
}

.detailsContainer {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 24px;
}

.detailsCol {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.detailsRow {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.detailsRow h5 {
  font-size: 14px;
  color: #40404c;
}

.detailsRow p {
  font-size: 16px;
  color: var(--gray-950);
}
