.listItem {
  width: 100%;
  border-radius: 24px;
  position: relative;
  background: var(--gray-50);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.noAppointment {
  color: var(--gray-300);
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px;
}

.appointmentBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  display: flex;
  padding: 5px 24px;
  min-height: 32px;
  border: 1px solid var(--gray-400);
  justify-content: space-between;
  width: 100%;
}

.appointmentBoxContentWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  max-height: 48px;
}

.changeStatusButtonWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.appointmentBoxContentName {
  font-size: 16px;
  font-weight: 700;
}

.appointmentBoxContentType {
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}

.appointmentBoxContentStatus {
  font-size: 14px;
  font-weight: 700;
}

.appointmentBoxContentStatusWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.worningimg {
  width: 100px;
  height: 100px;
  background: #fff0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.worningimg img {
  width: 50px;
  height: 50px;
}

.deletext h5 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-top: 32px !important;
  text-align: center;
  margin-bottom: 32px !important;
}
