/* src/components/ChatWidget/chat-widget.module.css */
.fixedChatButton {
  position: fixed;
  bottom: 1.25rem; /* 5 * 0.25rem */
  right: 1.25rem; /* 5 * 0.25rem */
  z-index: 50;
  height: 3.5rem; /* 14 * 0.25rem */
  width: 3.5rem; /* 14 * 0.25rem */
  border-radius: 9999px; /* full */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.addChatForm {
  padding: 16px;
  margin-bottom: 0;
}
.button {
  color: white;
  background-color: var(--green-800);
  border: 1px solid var(--green-800);
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
  font-weight: 600;
}

.sendButton {
  background-color: var(--green-800);
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px 24px;
  height: 48px;
}

.sendButton:disabled {
  background-color: #e5e7eb;
  color: #6b7280;
  cursor: not-allowed;
}

.unreadBadge {
  position: absolute;
  bottom: -0.75rem; /* -3 * 0.25rem */
  right: -0.75rem; /* -3 * 0.25rem */
  display: flex;
  height: 1.25rem; /* 5 * 0.25rem */
  width: 1.25rem; /* 5 * 0.25rem */
  align-items: center;
  justify-content: center;
  border-radius: 9999px; /* full */
  background-color: #ef4444; /* red-500 */
  font-size: 0.75rem; /* text-xs */
}

.chatContainer {
  position: fixed;
  bottom: 1rem; /* 4 * 0.25rem */
  right: 1rem; /* 4 * 0.25rem */
  z-index: 50;
  transition: transform 0.3s ease-in-out;
}

.card {
  display: flex;
  flex-direction: column;
  height: 31.25rem; /* 500px */
  width: 18rem; /* 72 * 0.25rem */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e7eb; /* border color */
  padding: 0.5rem; /* 2 * 0.25rem */
}

.cardTitle {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 1.125rem; /* text-lg */
  font-weight: 500; /* font-medium */
}

.cardContent {
  flex: 1;
  padding: 0;
}
