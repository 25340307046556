.duration15 {
  height: 50px;
}

.duration30 {
  height: 110px;
}

.duration45 {
  height: 175px;
}

.duration60 {
  height: 235px;
}

.container {
  min-height: 600px;
  overflow-y: auto;
  padding-right: 24px;
  position: relative;
  flex-grow: 1;
  padding-bottom: 10px;
}

.containerWrapperInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 8px;
  padding-bottom: 10px;
}

.container::-webkit-scrollbar {
  width: 6px;
}

.container::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: var(--gray-200);
  border-radius: 12px;
}

.container::-webkit-scrollbar-thumb {
  background: var(--gray-600);
  border-radius: 12px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400);
}

.freeTimeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;
}

.listContainer {
  margin-top: 4px;
  display: flex;
  gap: 24px;
  padding-bottom: 10px;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.time {
  color: #8d8da3;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.487px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  position: relative;
  padding-left: 24px;
}

.pastTime {
  color: #d9d9d9;
}

.currentTime {
  color: var(--green-800);
}

.futureTime {
  font-weight: 600;
  color: var(--gray-950);
}

.time::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  position: absolute;
  border-radius: 100%;
  left: 0;
  z-index: 1;
}

.time.pastTime:before,
.time.pastTime:after {
  background: #d9d9d9;
}

.time::after {
  content: "";
  background: #ececfa;
  width: 1px;
  position: absolute;
  left: 4.5px;
  height: 100%;
  top: 30px;
}

.time.currentTime:before,
.time.currentTime:after {
  background: var(--green-800);
}

.time.futureTime:before,
.time.futureTime:after {
  background: var(--gray-950);
}

.time:last-child::after {
  display: none;
}

.time + .time {
  margin-top: 12px;
}
