.actionBtn {
  color: #060030;
  background-color: transparent;
  font-size: 14px;
  letter-spacing: -0.56px;
  padding-left: 10px;
  padding-right: 5px;
  font-weight: 600;
  line-height: normal;
  border: none;
  border-left: 1px solid #dbdbec;
  border-radius: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.drawerContent {
  width: 800px;
  max-width: 90vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--gray-200);
}

.drawerHeader h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.tableWarpper {
  position: relative;
  background-color: #ffffff;
  background: #fff;
  margin: 24px;
}

.tableWarpper h3 {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.table {
  border-radius: 12px;
  border: 1px solid var(--gray-200);
}

.tableHeader {
  display: grid;
  gap: 8px;
  padding: 16px;
  align-items: center;
  grid-template-columns: 2fr 3fr  1fr 150px;
  background: var(--gray-100);
  color: var(--gray-950);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  border-bottom: 1px solid var(--gray-200);
}

.tableItem {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr  150px;
  gap: 16px;
  padding: 16px 16px 16px 24px;
  border-bottom: 1px solid #e0e0e0;
  color: var(--gray-950);
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  transition: background-color 0.2s ease;
}

@media (min-width: 1400px) {
  .tableItem,
  .tableHeader {
    grid-template-columns: 2fr 3fr 1fr  150px;
  }
}

.tableItem:last-child {
  border-bottom: none;
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchWrapper {
  border-right: 1px solid var(--gray-200);
  max-width: 330px;
}

@media (max-width: 991px) {
  .searchWrapper img {
    display: none;
  }
}

.tableTopBarWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-200);
}

.sortByButton {
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
  height: 52px;
  border-left: 1px solid var(--gray-200);
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sortByButtonMenuOptionActive {
  background-color: var(--gray-100) !important;
}

.tableSelectedItemsSection button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--green-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.menuItem a,
.menuItem button,
.menuItem p {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}

.reportNoReportsWarning {
  color: var(--black);
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin: 64px 24px;
  text-align: center;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyState > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 24px;
  text-align: left;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 300px;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
  color: #717186;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searchWrapper input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
}

.sortByButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #f8f9fa;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.emptyStateTitle {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.emptyStateText {
  font-size: 14px;
  color: #717186;
  max-width: 300px;
}

.actionsCell {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.actionsCell button {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  border-radius: 4px;
}

.actionsCell button:hover {
  background: var(--gray-100);
}

.completedTask {
  background: var(--success-50) !important;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #fff;
  border-top: 1px solid var(--gray-200);
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--gray-200);
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
  backdrop-filter: blur(4px);
}

.modal {
  background: white;
  border-radius: 12px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modalHeader {
  padding: 20px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeader h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
}

.modalCloseButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);
  transition: all 0.2s;
}

.modalCloseButton:hover {
  background: var(--gray-100);
  color: var(--gray-900);
}

.modalCloseButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modalContent {
  padding: 12px 24px 24px;
  color: var(--gray-700);
}

.modalContent p {
  margin: 0;
  line-height: 1.5;
}

.modalSubtext {
  margin-top: 8px !important;
  font-size: 14px;
  color: var(--gray-500);
}

.modalActions {
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid var(--gray-200);
  background: var(--gray-50);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.cancelButton,
.deleteButton {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.2s;
  min-width: 100px;
  justify-content: center;
}

.cancelButton {
  border: 1px solid var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  height: 42px;
  background-color: white;
}

.deleteButton {
  background-color: var(--green-900);
  border-radius: 12px;
  padding: 12px 24px;
  height: 42px;
  color: white;
  border: none;
}

.deleteButton:disabled,
.cancelButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.patientCell {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.completedRow {
  background-color: #e6f7e6 !important;
  border-left: 4px solid #28a745 !important;
}

.completedIcon {
  color: #28a745;
  background: #d4edda;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-5px);
}

.completedText {
  color: var(--gray-500);
  text-decoration: line-through;
}

.completedAction {
  background-color: #d4edda !important;
  color: #28a745 !important;
}

.completedAction img {
  opacity: 0.7;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.actionButton:hover:not(:disabled) {
  background: var(--gray-100);
}

.actionButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
