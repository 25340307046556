.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #fff;
  -webkit-box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px #7885b226;
  box-shadow: 0px 2.3326871395111084px 6.998061180114746px 0px #7885b226;
  border-radius: 20px;
  border: 1px solid #ececfa;

  padding: 24px;
}

.appointmentsPatientContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column: -1 / 1;
}

.appointmentsContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
}

.verticalDivider {
  width: 1px;
  background-color: var(--gray-200);
  height: 100%;
}

.divider {
  height: 1px;
  background-color: var(--gray-200);
  width: 100%;
}

@media screen and (min-width: 1300px) {
  .appointmentsPatientContainer {
    display: grid;
    grid-template-columns: 33% 1px 33% 1px auto;
  }

  .divider {
    width: 1px;
    background-color: var(--gray-200);
    height: 100%;
  }
}

.horizontalDivider {
  height: 1px;
  background-color: var(--gray-200);
  width: 100%;
}

.appointmentsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.summaryHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.summaryTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0 !important;
}

/* not AlliedHome.jsx component styles */

.appointmentBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  display: flex;
  padding: 5px 24px;
  min-height: 32px;
  border: 1px solid var(--gray-400);
  justify-content: space-between;
  width: 100%;
}

.appointmentBoxContentWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  max-height: 48px;
}

.changeStatusButtonWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteButton {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.appointmentBoxContentName {
  font-size: 16px;
  font-weight: 700;
}

.appointmentBoxContentType {
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}

.appointmentBoxContentStatus {
  font-size: 14px;
  font-weight: 700;
}

.noAppointment {
  color: var(--gray-300);
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px;
}

.active {
  outline: 2px solid #006546;
  outline-offset: 2px;
}

.cancelled,
.cancelled2,
.cancelled svg {
  background-color: #d9d8df !important;
  color: black !important;
  border-color: #706f73 !important;
  stroke: black !important;
}

.cancelled2::before {
  background-color: #706f73 !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.arrived,
.arrived2,
.arrived svg {
  background-color: #e7efeb !important;
  color: #006546 !important;
  border-color: #006546 !important;
  stroke: #006546 !important;
}

.arrived2::before {
  background-color: #006546 !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.inConsultation,
.inConsultation2,
.inConsultation svg {
  background-color: #f1f6ff !important;
  color: #2676ff !important;
  border-color: #2676ff !important;
  stroke: #2676ff !important;
}

.inConsultation2::before {
  background-color: #2676ff !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.completed,
.completed2,
.completed svg {
  background-color: #fff8e4 !important;
  color: #987716 !important;
  border-color: #987716 !important;
  stroke: #987716 !important;
  /* position: relative; */
}

.completed2::before {
  background-color: #987716 !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.due,
.due2,
.due svg {
  background-color: #8f8fa1 !important;
  color: black !important;
  border-color: #8f8fa1 !important;
  stroke: black !important;
}

.due2::before {
  background-color: #8f8fa1 !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

.default,
.default2,
.default svg {
  background-color: #f7f7f8 !important;
  color: #8f8fa1 !important;
  border-color: #8f8fa1 !important;
  stroke: #8f8fa1 !important;
}

.default2::before {
  background-color: #8f8fa1 !important;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}
.appointmentBoxContentStatusWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sortByButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.sortByButton {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  padding: 4px 4px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  align-self: stretch;
  border: none;
  background-color: transparent;
}

.sortByButtonMenuOption p {
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.menuButton {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 2px;
}

.menuButton:hover,
.menuButton:active {
  border: 1px solid var(--gray-300);
}

.menuItemWrapper {
  padding: 0;
}
.menuItem {
  padding: 0 !important;
}

.worningimg {
  width: 100px;
  height: 100px;
  background: #fff0f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.worningimg img {
  width: 50px;
  height: 50px;
}

.deletext h5 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-top: 32px !important;
  text-align: center;
  margin-bottom: 32px !important;
}

.menuItem a,
.menuItem button,
.menuItem p {
  font-family: Nunito;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-950);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 12px 20px;
}

.deleteButton .deleteIcon {
  color: #dc3545 !important;
  stroke: #dc3545 !important;
}
