.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.headerTitle {
  font-size: 20px;
  font-weight: 600;
}

.headerTasksQuantity {
  width: 20px;
  height: 20px;
  border-radius: 32px;
  background: var(--green-800);
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItem {
  display: grid;
  grid-template-columns: 20px 1fr auto;
  gap: 24px;
  padding: 16px 0;
}

.listItem.borderBottom {
  border-bottom: 1px solid #ececfa;
}

.taskNumber {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--green-800);
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.taskTitle {
  cursor: pointer;
  font-size: 16px;
  color: var(--gray-950);
  width: fit-content;
}

.taskInfoContainer {
  display: flex;
  gap: 12px;
}

.taskDate {
  font-size: 14px;
}

.taskPatientContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.taskPatientName {
  font-size: 14px;
  color: var(--gray-800);
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actionButton {
  height: 35px;
  width: 35px;
}
